<template>
  <div>
    <b-card-body class="h-100 p-0">
      <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y  h-100" fill
        v-model="tabIndex">
        <b-tab title="Reference standard" Active>

          <div class="container text-center">
            <div class="annotation-images-row">
              <b-row align-h="center" class="pt-1">
                <b-input-group class="rounded-pill navy-fields">
                  <template #prepend>
                    <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
                      <font-awesome-icon icon="search" />
                    </b-input-group-text>
                  </template>
                  <template #append>
                    <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="search" placeholder="Search Image Name" style="width: 25rem"></b-form-input>
                  <b-col>
                    <b-dropdown class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                      :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                      <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                      <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
                        sg.name }}</b-dropdown-item>
                    </b-dropdown>
                      <p class="mt-0 pt-0 pl-3">Subgroups</p>
                  </b-col>

            <!-- <b-col>
              <b-dropdown class=" my-class ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                text="Pathology" variant="warn" >
                  <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                _p.label }}</b-checkbox>
              </b-dropdown>
            </b-col> -->
                </b-input-group>
              </b-row>
              <!-- Loop through the images and display them -->
              <b-row class="mx-0">
                <b-col v-for="image in paginatedData" :key="pageNumber + '_' + image.id" cols="3" class="mb-4">
                  <div class="image-container">
                    <b-col @click="openBottomSheet(image)">
                      <img :src="image.url + '?thumb=yes'"
                      alt="Uploaded Image"
                      class="img-fluid"
                      :class="image.details.image_set && image.details.image_set.name ? 'image-set' : ''"
                      :style="'border-color: ' + image.styleFlag + ';'" />
                      <div>
                        <small class="image-name text-wite">{{ image.details.image_set && image.details.image_set.name ? image.details.image_set.name : image.details.imgName  }}</small>
                      </div>
                    </b-col>
                    <!-- Display the indicator with red or green color based on 'trained' property -->
                    <small class="indicator"
                      :style="'background-color: ' + (image.styleFlag === 'white' ? '#14304c' : image.styleFlag)">
                      {{ image.styleFlag === 'purple' ? "Moderated" : image.styleFlag === 'orange' ? "Partial Agreement" :
                        image.styleFlag === 'red' ? "Full Disagreement" : "Full Agreement" }}
                    </small>
                    <small class="indicator-set trained-green" v-if="image.details.image_set && image.details.image_set.name">
                      {{ image.details.image_set.sequence }}
                    </small>
                  </div>
                </b-col>
              </b-row>

              <div class="text-center" v-if="paginatedData.length === 0">
                <!-- Show icon and message when images are empty -->
                <div class="empty-images mb-5">
                  <i class="fa fa-image fa-3x"></i>
                <p>No images Fully Annotated.</p>
                </div>
              </div>
              <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
            </div>
          </div>
        </b-tab>
        <b-tab title="Download">
          <!-- Loop through the images and display them -->
          <b-col style="min-height: 400px;">
            <b-row class="mt-2 ml-2 mb-0" cols="12" style="width: 100%;" align-h="center">
              <b-col cols="3">
                <div>
                  <datepicker placeholder="Date From" :bootstrap-styling="true" v-model="dateFrom"></datepicker>
                </div>
              </b-col>
              <b-col cols="3">
                <div>
                  <datepicker placeholder="Date To" :bootstrap-styling="true" input-class="datePicker" v-model="dateTo"
                    :disabled-dates="{ from: null, to: dateFrom }"></datepicker>
                </div>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <b-dropdown class=" my-class  ml-3 btn btn-yellow rounded-pill"
                      text="Pathology" variant="warn">
                        <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                      _p.label }}</b-checkbox>
                    </b-dropdown>
                    <b-button class="ml-3 btn btn-blue rounded-pill" @click="resetDates">
                      Reset Dates
                    </b-button>
                  <b-button class="ml-3 btn btn-yellow rounded-pill" @click="downloadImages">
                    Create Batch
                  </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-button class="ml-2 btn btn-yellow rounded-pill" @click="refreshBatches">
                Refresh
              </b-button>
            </b-row>
            <div v-if="images.batches">
              <div v-for="(batch, index) in images.batches.filter(b => b.projectid === project._id)" :key="batch._id">
                <b-row cols="12" class="batchTable">
                  <b-col cols="1">
                    <h5 style="color: azure;">{{ index }}</h5>
                  </b-col>
                  <b-col cols="4">
                    <p><strong style="color: azure;">Created by:</strong> {{ batch.created_by.personal.fullname }}</p>
                    <p><strong style="color: azure;">Created at:</strong> {{ formatDate(batch.created_at) }}</p>
                  </b-col>
                  <b-col cols="4">
                    <p><strong style="color: azure;">Total Images: </strong>{{ batch.total_images }}</p>
                    <p><strong style="color: azure;">Status: </strong>{{ batch.status }}</p>
                  </b-col>
                  <b-col cols="3">
                    <b-button class="btn btn-yellow rounded-pill" :disabled="downloadStop(batch.status)"
                      @click="openInNewTab(batch.zip_path)"> Download </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../../helpers/api-route'
import { IOVThesholds } from './../../../../helpers/iovthresholds'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'GroundTruth',
  props: {
    project: {}
  },
  data () {
    return {
      selectedImage: null,
      bottomSheetOpen: false,
      displayImages: [],
      displayImagesCache: [],
      dateFrom: '',
      dateTo: '',
      aiSubmission: [],
      memberData: [],
      fetchMem: false,
      fetchImg: false,
      tabIndex: 0,
      batchData: [],
      newData: 0,
      filterModalOpen: false,
      availableSubgroups: {},
      subgroupBase: {},
      subgroupChanged: 0,
      subgroupOptions: {},
      subGroupName: '',
      visibleImages: [],
      subgroupOptionsReset: {},
      subgroupReset: {},
      filterChoice: '',
      filterChoicePath: '',
      filterChoicePathArray: {},
      filterChoicePathArray2: [],
      search: '',
      pageNumber: 0,
      itemsPerPage: 16
    }
  },
  components: {
    Datepicker
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'downloadLabels', 'getBatches']),
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    refreshBatches () {
      this.getBatches()
    },
    openInNewTab (url) {
      // // // console.log(url)
      window.open(url)
    },
    downloadStop (status) {
      if (status === 'Completed') {
        return false
      } else {
        return true
      }
    },
    downloadImages () {
      var payload = {
        dateStart: this.dateFrom === '' ? new Date('2009-12-31') : this.dateFrom,
        dateEnd: this.dateTo === '' ? new Date() : this.dateTo,
        pathology: JSON.stringify(this.filterChoicePathArray2),
        projectID: this.project._id
      }
      this.downloadLabels(payload)
    },
    resetDates () {
      this.dateFrom = ''
      this.dateTo = ''
    },
    transformImageData (filterFlag) {
      const data = this.images.labelledImages
      if (!Array.isArray(data)) {
        return []
      }
      var transformedData = []
      var modFlag = false
      var acc
      // // // console.log(!filterFlag)
      transformedData = []
      for (const item of data) {
        // // // console.log('asdhgdfjsg', this.members)
        if (item.image && item.image.image && item.image.image.path && item.users.length >= this.readers.length && !item.image.removed.find(p => p.project === this.project._id)) {
          modFlag = false
          acc = 0
          item.users.forEach(us => {
            if (us.projectRole.find(pr => pr.project === this.project._id).role === 'Moderator') {
              modFlag = true
            }
          })
          if (item.image.accuracy.filter(a => a.project === this.project._id).length > 0) {
            acc = item.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value
          } else {
            acc = 0
          }
          var _acc = []
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _acc.push(_item.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value)
            })
          }
          // console.log('acc', acc)
          _acc.push(acc)
          // console.log('all acc', _acc)
          _acc = _acc.filter(a => a !== 0)
          if (_acc.length > 0) {
            // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
            acc = _acc.reduce((a, b) => a + b) / _acc.length
          } else {
            acc = 0
          }

          if (acc >= IOVThesholds().fullAgreement && !modFlag) {
            this.aiSubmission.push(item)
          }
          var _pathologies = []
          item.pathologies.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                _pathologies.push(sub[_k].name)
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.pathologies.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _pathologies.push(sub[_k].name)
                  }
                })
              })
            })
          }

          var _tags = []
          // console.log(item)
          item.tags.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                _tags.push(sub[_k])
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.tags.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _tags.push(sub[_k])
                  }
                })
              })
            })
          }
          var _diagnoses = []
          // console.log(item)
          item.diagnoses.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                // console.log('_k ', _k)
                // console.log('_k ', sub[_k])
                _diagnoses.push(sub[_k])
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.diagnoses.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _diagnoses.push(sub[_k])
                  }
                })
              })
            })
          }
          transformedData.push({
            id: item.image._id,
            url: apiRoute() + '/image/' + item.image._id,
            styleFlag: modFlag ? 'purple' : acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement ? 'orange' : acc > IOVThesholds().partialAgreement ? 'red' : 'white',
            details: {
              created: item.image.created_at,
              format: item.image.image.contentType,
              imgName: item.image.image.name,
              meta: item.image.metadata,
              iou: acc,
              pathologies: new Set(_pathologies),
              tags: new Set(_tags),
              diagnoses: new Set(_diagnoses),
              image_set: item.image.image_set && item.image.image_set.name ? item.image.image_set : null,
              otherImages: item.otherImages
            }
          })
        }
      }
      // // // console.log(transformedData)
      this.displayImagesCache = transformedData
      return transformedData
    },
    openBottomSheet (image) {
      if (this.selectedImage === image) {
        // Deselect the image if it's already selected
        this.selectedImage = null
      } else {
        // Select the image
        this.selectedImage = image
      }
      this.$emit('open-bottom-sheet', image)
    },
    hasPath (img, filter) {
      var temp = false
      if (this.filterChoicePathArray2.length === 0) {
        temp = true
      }
      Object.keys(filter).forEach(_ch => {
        if (_ch === 'Other' && this.filterChoicePathArray[_ch]) {
          img.details.pathologies.forEach(v => {
            if (!this.project.pathologies.find(p => p.label === v)) {
              temp = true
            }
          })
          // // console.log(img.details.pathologies.values())
          // // console.log(this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1) && this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1
          // && this.project.pathologies.filter(p => img.details.pathologies.has(p))) {p => !this.project.pathologies.includes(p)
        } else if (_ch !== 'Other' && this.filterChoicePathArray[_ch] && img.details.pathologies.has(_ch)) {
          temp = true
        }
      })
      return temp
    },
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.details.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            if (img.details.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.details.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.details.meta && img.details.meta[0] && img.details.meta[0] && img.details.meta[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.details.meta[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    inSearch  (img, val) {
      if (img.details.image_set && img.details.image_set.name) {
        return img.details.image_set.name.includes(val) || img.details.image_set.sequence.includes(val)
      } else {
        return img.details.imgName.includes(val)
      }
    },
    addPath (val) {
      if (this.filterChoicePathArray2.includes(val)) {
        this.filterChoicePathArray2 = this.filterChoicePathArray2.filter(p => p !== val)
      } else {
        this.filterChoicePathArray2.push(val)
      }
    },
    compareLabel (a, b) {
      return a.label.localeCompare(b.label)
    }
  },
  computed: {
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    reducedImages () {
      this.filterChoicePathArray2.forEach(_key => {
        // console.log('key', _key)
      })
      var _images = this.displayImages
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => this.hasGroup(img, this.filterChoice))
      }
      if (this.search !== '') {
        _images = _images.filter(img => this.inSearch(img, this.search))
      }
      return _images
    },
    pathList () {
      var paths = this.project.pathologies
      var _paths = paths.sort(this.compareLabel)
      return _paths
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.reducedImages.slice(start, end)
    },
    maxPages () {
      return (this.reducedImages.length / this.itemsPerPage >> 0) + 1
    },
    imagesLoaded () {
      return this.images.loaded
    },
    downloading () {
      return this.images.downloading
    },
    loadingBatches () {
      return this.images.bacthLoading
    },
    readers () {
      // // // console.log('read', this.members.members)
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    filterChange () {
      return this.filterChoicePathArray2
    }
  },
  watch: {
    filterChange (_new) {
      // console.log('change', _new)
    },
    imagesLoaded (_new) {
      if (_new) {
        this.displayImages = this.transformImageData(false)
      }
    },
    downloading (_new) {
      if (!_new) {
        this.getBatches()
        this.newData++
      }
    },
    loadingBatches (_new) {
      // // // console.log('new batch')
      if (!_new) {
        this.newData++
      }
    }
  },
  mounted () {
    console.log(IOVThesholds().fullAgreement)
    this.getBatches()
    this.displayImages = this.transformImageData(false)
    this.project.pathologies.forEach(_p => {
      this.filterChoicePathArray[_p.label] = false
    })
  }
}
</script>
<style>
.indicator {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}
.empty-images {
  color: #ccc;
  margin-top: 50px;
}

.image-name {
  color: white;
  margin-top: 5px;
  font-size: 14px;
}

.trained-green {
  background-color: green;
}

.trained-red {
  background-color: #14304c;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* Add cursor pointer for clickable effect */
}

.vdp-datepicker__calendar {
  margin-top: 10px !important;
  background: #0a223a !important;
  border: 1px solid rgb(255, 153, 0) !important;
  border-radius: 25px !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: #154575 !important;
}
</style>
<style scoped>
.batchTable {
  border-top: 1px solid #573f0880;
  padding-top: 10px;
  margin-bottom: 5px;

}

.annotation-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-fluid {
  border-radius: 10px;
  border: 2px solid white;
}

</style>
<style scoped>
  .my-class /deep/ .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
.indicator-set {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 35px;
  left: 5px;
}
</style>
