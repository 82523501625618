<template>
  <div class="text-center">
    <p class="text-white">
     You're about to remove {{ image.image.name }} from the project?
    </p>
    <div class="mt-3">
      <input
        v-model="removalReason"
        type="text"
        class="form-control"
        placeholder="Enter reason"
      />
    </div>

    <div class="d-flex justify-content-center mt-4">
      <b-button
        variant="danger"
        @click="removeImage"
        :disabled="!isRemoveButtonEnabled"
        class="d-flex align-items-center rounded-pill bg-red"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm mr-2"
        ></span>
        Remove
      </b-button>
      <b-button
        class="mx-2 rounded-pill"
        variant="secondary"
        @click="cancelDelete"
        :disabled="loading"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      removalReason: ''
    }
  },
  computed: {
    isRemoveButtonEnabled () {
      return this.removalReason.trim() !== ''
    }
  },
  methods: {
    async removeImage () {
      this.loading = true

      const reason = this.removalReason
      // // console.log('this is the reason', reason)

      this.$emit('delete-image', reason)

      this.loading = false
    },
    cancelDelete () {
      this.$emit('delete-image-success')
    }
  }
}
</script>
