<template>
  <div class="mx-0">
    <div class="container text-center">
      <b-row align-h="center" class="pt-1">
        <b-input-group class="rounded-pill navy-fields">
          <template #prepend>
            <b-input-group-text
              class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control"
            >
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text
              class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control"
            >
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search Image Name"
            style="width: 25rem"
          ></b-form-input>
          <b-dropdown  class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" :text="filterChoice === '' ?  'All':filterChoice " variant="warn">
            <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
            <b-dropdown-item v-for="sg in projectId.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
          </b-dropdown>
        </b-input-group>
      </b-row>
      <b-row >
        <b-col
          v-for="(image, index) in paginatedData"
          :key="pageNumber + '_' + index"
          cols="3"
          class="mb-4"
        >
          <div class="image-container">
            <b-col @click="openBottomSheet(image)">
              <img :src="getRoute+'/image/' + image._id + '?thumb=yes'" alt="Uploaded Image" class="img-fluid" :class="image.image_set && image.image_set.name ? 'image-set' : ''" />
              <div class="mt-1">
                <small class="image-name text-wite">{{ image.image_set && image.image_set.name ? image.image_set.name : image.image.name }}</small>
              </div>
            </b-col>
            <!-- Display the indicator with red or green color based on 'trained' property -->
            <small class="indicator" :class="isAnnotated(image) ? 'trained-green' : 'trained-red'">
              {{ isAnnotated(image) ? "Annotated" : "Not Annotated" }}
            </small>
            <small class="indicator-set trained-green" v-if="image.image_set && image.image_set.name">
              {{ image.image_set.sequence }}
            </small>
            <button v-if="!isAnnotated(image)" @click="removeImage(image)" class="remove-button">
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <b-modal
            v-model="showRemoveImageModal"
            centered
            size="md"
            hide-footer
            header-close-content="<i class='fas fa-times color-white'></i>"
          >
            <template #modal-title>
              <h4 class="ml-4 text-white">Remove Image from Project</h4>
            </template>
            <remove-image-reason :image="selectedImage" @delete-image="sendRemoval"></remove-image-reason>
          </b-modal>
        </b-col>
    </b-row>
      <div class="text-center" v-if="visibleImages.length === 0">
        <!-- Show icon and message when images are empty -->
        <div class="empty-images mb-5">
          <i class="fa fa-image fa-3x"></i>
          <p>No images Uploaded.</p>
        </div>
      </div>
    <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RemoveImageReason from './RemoveImageReason.vue'
import { apiRoute } from '../../../../helpers'

export default {
  components: {
    RemoveImageReason
  },
  props: {
    projectId: null
  },
  data () {
    return {
      visibleImages: [],
      imagesLoaded: 0,
      showRemoveImageModal: false,
      selectedImage: null,
      filterChoice: '',
      visibleImagesChache: [],
      search: '',
      pageNumber: 0,
      itemsPerPage: 16
    }
  },
  computed: {
    ...mapState('projectSubmission', {
      images: (state) => state.projectImages,
      imagesWatch: (state) => state.loadingProjectImages
    }),
    shouldLoadMore () {
      return this.visibleImages.length < this.images.length
    },
    getRoute () {
      return apiRoute()
    },
    filterChange () {
      return this.filterChoice
    },
    searchImg () {
      return this.search
    },
    imagesLoad () {
      return this.imagesWatch
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.visibleImages.slice(start, end)
    },
    maxPages () {
      return (this.visibleImages.length / this.itemsPerPage >> 0) + 1
    }
  },
  watch: {
    imagesLoad (_new) {
      if (!_new) {
        this.loadImagesForProject()
      }
      // // console.log('new project')
    },
    filterChange (_new) {
      if (_new !== 'All') {
        // // console.log('filter')
        this.visibleImages = []
        // // console.log(this.visibleImages)
        // // console.log(this.visibleImagesChache)
        this.visibleImagesChache.forEach(item => {
          let includeInFilter = false
          var _filter = this.projectId.subgroups.find(sg => sg.name === this.filterChoice).definiation
          // // console.log(_filter)
          Object.keys(_filter).forEach(_key => {
            if (_key !== 'Pathologies' && _key !== 'Diagnosis' && _key !== 'Tags') {
              if ((item.metadata && item.metadata[0] && item.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(item.metadata[0].values.find(_meta => _meta.name === _key).value))) {
                // console.log('meta')
                includeInFilter = true
              }
              // // console.log(item.metadata[0].values.find(_meta => _meta.name === _key).value)
            }
          })
          if (includeInFilter) {
            this.visibleImages.push(item)
          }
        })
        this.visibleImages = this.visibleImages.filter(img => {
          if (img.image_set && img.image_set.name) {
            return img.image_set.name.includes(this.search) || img.image_set.sequence.includes(this.search)
          } else {
            return img.image.name.includes(this.search)
          }
        })
      } else {
        this.visibleImages = this.visibleImagesChache
      }
      // this.$emit('filterImages', _new)
    },
    searchImg (_new) {
      // // console.log(this.visibleImagesChache)
      this.visibleImages = this.visibleImagesChache.filter(img => {
        if (img.image_set && img.image_set.name) {
          return img.image_set.name.includes(this.search) || img.image_set.sequence.includes(this.search)
        } else {
          return img.image.name.includes(this.search)
        }
      })
      // console.log(this.visibleImages)
      if (this.filterChoice === '' || this.filterChoice === 'All') {
        this.visibleImages = this.visibleImagesChache.filter(img => {
          if (img.image_set && img.image_set.name) {
            return img.image_set.name.includes(this.search) || img.image_set.sequence.includes(this.search)
          } else {
            return img.image.name.includes(this.search)
          }
        })
      } else {
        this.visibleImages = []
        this.visibleImagesChache.forEach(item => {
          let includeInFilter = true
          var _filter = this.projectId.subgroups.find(sg => sg.name === this.filterChoice).definiation
          // // console.log(_filter)
          Object.keys(_filter).forEach(_key => {
            // console.log(_key)
            if (_key !== 'Pathologies') {
              if (!(item.metadata && item.metadata[0] && item.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(item.metadata[0].values.find(_meta => _meta.name === _key).value))) {
                includeInFilter = false
              }
            }
            // // console.log(item.metadata[0].values.find(_meta => _meta.name === _key).value)
          })
          if (includeInFilter) {
            this.visibleImages.push(item)
          }
        })
        this.visibleImages = this.visibleImagesChache.filter(img => {
          if (img.image_set && img.image_set.name) {
            return img.image_set.name.includes(this.search) || img.image_set.sequence.includes(this.search)
          } else {
            return img.image.name.includes(this.search)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('projectSubmission', ['getProjectImages2', 'removeImageFromProject']),
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    serachImages () {

    },
    isAnnotated (image) {
      if (image.accuracy.find(p => p.project === this.projectId._id)) {
        return true
      } else {
        return false
      }
    },
    loadImagesForProject () {
      try {
        const loadedImages = this.images
        if (loadedImages) {
          this.visibleImages = [...loadedImages]
          this.visibleImagesChache = [...loadedImages]
        } else {
          this.visibleImages = []
          this.visibleImagesChache = []
        }
      } catch (error) {
        console.error('Error loading images:', error)
      }
    },
    closeRemoveImageModal () {
      this.showRemoveImageModal = false
    },
    // onImageLoad (index) {
    //   this.visibleImages[index].loading = false
    // },
    async sendRemoval (reason) {
      // projectId, imageIds, reason
      // // // console.log('removal', reason)
      // // // console.log(this.projectId._id)
      // // // console.log(this.selectedImage._id)
      var payload = { reason: reason, p_id: this.projectId._id, removalIds: [], phase: 1 }
      payload.removalIds.push(this.selectedImage._id)
      await this.removeImageFromProject(payload).then(this.showRemoveImageModal = false)
      this.getProjectImages2(this.projectId._id)
    },
    removeImage (image) {
      // // console.log('img', image)
      this.selectedImage = image
      // this.shouldLoadMore = true
      this.showRemoveImageModal = true
      // // console.log('this is the removed image', image.id)
    },
    openImage (index) {
      // // console.log('Clicked on image with index:', index)
    },
    openBottomSheet (image) {
      // // console.log('abc', image)
      if (this.selectedImage && this.selectedImage.id === image._id) {
        // Deselect the image if it's already selected
        this.selectedImage = null
      } else {
        // Select the image
        this.selectedImage = {
          id: image._id,
          style: 'green',
          url: this.getRoute + '/image/' + image._id,
          details: {
            created: image.created_at,
            format: image.image.contentType,
            imgName: image.image_set && image.image_set.name ? image.image_set.name : image.image.name,
            iou: 0,
            image_set: image.image_set,
            otherImages: image.otherImages,
            meta: image.metadata,
            pathologies: null
          }
        }

        // console.log('abc', this.selectedImage)
      }

      this.$emit('open-bottom-sheet', this.selectedImage)
    }
  },
  mounted () {
    if (this.projectId._id) {
      // this.getProjectImages2(this.projectId._id)
      this.loadImagesForProject()
    }
  }
}
</script>

<style scoped>
.empty-images {
  color: #ccc;
  margin-top: 50px;
}

.uploaded-images {
  border-radius: 10px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Add cursor pointer for clickable effect */
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 15%;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.remove-button i {
  font-size: 18px;
}

/* Style the images */
.img-fluid {
  border-radius: 10px;
  border: 0.5px solid white;
}

.indicator {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.indicator-set {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 35px;
  left: 5px;
  z-index: 1;
}

.trained-green {
  background-color: green;
}

.trained-red {
  background-color: #14304ced;
}
</style>
