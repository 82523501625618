<template>
  <div id="MainView">
    <b-navbar toggleable="lg" type="dark" class="px-4 py-3">
      <b-nav-form class="navy-fields">
        <b-input-group class="rounded-pill">
          <template #prepend>
            <b-input-group-text
              class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control"
            >
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text
              class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control"
            >
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search Projects"
            style="width: 25rem"
          ></b-form-input>
        </b-input-group>
      </b-nav-form>
        <b-button v-if="!sortFlag" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()">  <font-awesome-icon icon="sort-amount-up"  /> </b-button>
        <b-button v-else class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()">  <font-awesome-icon icon="sort-amount-down"  /> </b-button>
        <b-button class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="openProjectSelector()">   <font-awesome-icon icon="filter"  /></b-button>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-4">
            <b-nav-item
              href="https://www.edai.africa/contact-us/"
              target="_blank"
              link-classes="btn px-3 btn-sm btn-yellow rounded-pill"
            >
              <font-awesome-icon icon="info" />
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="px-4 inner-container">
      <!-- v-model="isUpdateModalOpen"
      @hidden="resetModal"
      centered -->
      <b-modal
        v-model="isProjectSelectModalOpen"
        header-close-content="<i class='fa fa-times color-white'></i>"
        hide-footer
      >
        <template #modal-title>
          <b-row class="mx-2">
          <h4 class="mx-1 text-white">Project Selector</h4>
        </b-row>
          <b-row class="mx-2" align-h="around">
        <b-button class="ml-1 btn px-3 btn btn-yellow rounded-pill" @click="selectAll(true)">  Select All </b-button>
        <b-button class="ml-1 btn px-3 btn btn-blue rounded-pill" @click="selectAll(false)">  Deselect All </b-button>
        </b-row>
          <b-form-group :key="projectRender">
            <div
              v-for="project in allProjects"
             :key="project._id">
            <b-form-checkbox
            v-model="displayedProjects[project._id]"
             >
             <p style="color: antiquewhite;">{{project.name}}</p>
            </b-form-checkbox>
            </div>
          </b-form-group>
        </template>
      </b-modal>
      <b-row class="inner-row">
        <b-col cols="4" class="inner-col d-flex flex-column">
          <!-- Accordion -->
          <!--<div style="height: calc(100vh); overflow-y: auto">-->
            <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist">
              <template v-for="project in filterProject">
              <!-- v-if="displayedProjects[project._id]===true"> -->
                <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" :key="'ch_' + project._id">
                    <!-- v-b-toggle="project._id" -->
                  <b-button block :class="!accordainState[project._id] ? 'collapsed':''" @click="expandAccordain(project._id)">
                    <Strong style="color: antiquewhite;">{{ project.name }}:</Strong> <small class="mx-1"> Created: {{ formatDate(project.date_updated) }}</small>
                  </b-button>
                </b-card-header>
                <b-collapse :id="project._id" :visible="selected === project._id" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-model="accordainState[project._id]" :key="'body_' + project._id">
                  <list-view :list="filteredList(project._id)" :active="selected" v-on:phase-selected="selectPhase"></list-view>
                  <!-- :dates="getListDates(project.id)" -->
                </b-collapse>
              </template>
              <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
                <b-button block class="text-center color-yellow font-weight-900" :class="!accordainState['createNewProject'] ? 'collapsed':''" @click="expandAccordain('createNewProject')">
                  Add new project
                </b-button>
                <b-collapse id = 'createNewProject' v-model="accordainState['createNewProject']">
                  <div class="mx-2">
                  <create-project @project-created="closeCreateProject"></create-project>
                  </div>
                </b-collapse>
              </b-card-header>
            </div>
          <!--</div>-->
        </b-col>
        <b-col cols="8" class="inner-col d-flex flex-column">
          <ai-summary-view
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            v-if="selected && selected === 8"
          ></ai-summary-view>
          <gallery-view
            v-else-if="selected"
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            @phase-selected="selectPhase"
          ></gallery-view>
          <nothing-view v-else></nothing-view>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { apiRoute, authHeader } from './../../helpers'
import { mapState, mapActions } from 'vuex'
// import moment from 'moment'
import AiSummaryView from './components/Results/Summary.vue'
import CreateProject from './components/CreateProject'
import ListView from './components/List'
import GalleryView from './components/GalleryView'
import NothingView from './components/Nothing'

export default {
  components: {
    AiSummaryView,
    ListView,
    GalleryView,
    NothingView,
    CreateProject
  },
  data () {
    return {
      isCreateProjectModalOpen: false,
      activeAccordion: null,
      current: null,
      interval: null,
      period: 'now',
      allLoaded: false,
      selected: null,
      projectId: null,
      search: '',
      now: [],
      earlier: [],
      yesterday: [],
      week: [],
      older: [],
      key: 0,
      accordainState: {},
      filterProject: [],
      sortFlag: true,
      projectSelector: -1,
      allProjects: [],
      displayedProjects: {
      },
      tflag: [],
      isProjectSelectModalOpen: false,
      firstPull: true,
      projectRender: 0
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      projects: (state) => state.projectSubmission
    }),
    loadState () {
      return this.projects.loading
    },
    getSearch () {
      return this.search
    },
    selectedData () {
      // console.log('this is selected', this.selected)
      return this.selected
    },
    sortOrientation () {
      return this.sortFlag
    }
  },
  methods: {
    ...mapActions('staffSubmission', {
      getAllSubmissions: 'getCovid',
      clearSubmissions: 'clear'
    }),
    ...mapActions('projectSubmission', ['getUserProjects', 'selectProject']),
    handleProjectCreated () {
      this.getUserProjects()
    },
    ...mapActions('BottomSheet', {
      bsclear: 'clear'
    }),
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    selectAll (val) {
      Object.keys(this.displayedProjects).forEach(p => {
        this.displayedProjects[p] = val
      })
      this.projectRender++
    },
    openProjectSelector: function () {
      this.isProjectSelectModalOpen = true
      // console.log('open project select', this.isProjectSelectModalOpen)
    },
    toggleAccordion: function () {
      // console.log(this.accordainState)
    },
    toggleSort: function () {
      this.selected = null
      this.sortFlag = !this.sortFlag
    },
    mapAccordain: function () {
      this.allProjects = this.projects.projects
      this.allProjects.forEach(_p => {
        if (!this.displayedProjects[_p._id]) {
          this.displayedProjects[_p._id] = true
        }
      }
      )
      this.filterProject = this.allProjects.reverse().slice(0, 5)
      this.accordainState.createNewProject = false
      this.firstPull = false
    },
    expandAccordain: function (_proID) {
      this.bsclear()
      this.selectProject(_proID)
      if (this.accordainState[_proID]) {
        this.accordainState[_proID] = false
      } else {
        this.accordainState = Object.assign(...Object.keys(this.accordainState).map(k => ({ [k]: false })))
        this.accordainState[_proID] = true
      }
    },
    refreshStudies: function () {},
    filteredList (projectId) {
      return this.projects.projects.filter((project) => project._id === projectId)
    },
    openCreateModal () {
      this.isCreateProjectModalOpen = true
    },
    closeCreateProject () {
      this.isCreateProjectModalOpen = false
      this.firstPull = true
      this.getUserProjects()
    },
    displayResult: async function (data) {
      this.current = data

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(
        apiRoute() +
          '/api/v1/staff/covid/heatmaps/' +
          this.current.submission._id,
        requestOptions
      )
      const results = await _response.json()
      this.current.heatmaps = results
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getConclusionClass: function (name) {
      if (name.indexOf('high') > -1) {
        return 'text-danger'
      } else if (name.indexOf('intermediate') > -1) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    },
    getCurrentClass: function (_id) {
      if (this.current && this.current.submission) {
        if (this.current.submission._id === _id) {
          // console.log('Selected')
          return 'selected-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    originalImage: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].path
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].response.bounding.path
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology =
          result.attachments[0].response.labels[0].pathologies.find(
            (pathology) => pathology.predicted_diagnosis === 'cardiomegaly'
          )
        if (_pathology) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    selectPhase (id) {
      this.selected = id
    },
    onCollapseState: function (collapseId, isJustShown) {
      if (isJustShown) {
        this.projectId = collapseId
        this.selected = null
      }
    }
  },
  mounted () {
    // this.getAllSubmissions(this.period)
    this.firstPull = true
    this.$root.$on('bv::collapse::state', this.onCollapseState)
    this.getUserProjects()
  },
  destroyed () {
    this.$root.$off('bv::collapse::state', this.onCollapseState)

    clearInterval(this.interval)
    this.interval = null
  },
  watch: {
    loadState (loading) {
      // console.log('project watch', loading)
      if (!loading && this.firstPull) {
        this.mapAccordain()
      }
    },
    getSearch (_new) {
      // // console.log(_new)
      this.selected = null
      var _tProjects = []
      this.allProjects.forEach(_p => {
        if (_p.name.toLowerCase().includes(_new.toLowerCase()) && this.displayedProjects[_p._id]) {
          _tProjects.push(_p)
        }
      })
      if (this.sortFlag) {
        this.filterProject = _tProjects.slice(0, 5)
      } else {
        this.filterProject = _tProjects.reverse().slice(0, 5)
      }
    },
    isProjectSelectModalOpen (_new) {
      // // console.log('filter')search
      if (!_new) {
        this.selected = null
        var _tProjects = []
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        if (this.sortFlag) {
          this.filterProject = _tProjects.slice(0, 5)
        } else {
          this.filterProject = _tProjects.reverse().slice(0, 5)
        }
      }
      // var _projects = this.projects.projects
      // _projects = _projects.filter((_p) => this.displayedProjects[_p.id])
      // this.allProjects = _projects
    },
    sortOrientation (_new) {
      var _tProjects = []
      if (_new) {
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        this.filterProject = _tProjects.slice(0, 5)
      } else {
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        this.filterProject = _tProjects.reverse().slice(0, 5)
      }
    }
  }
}
</script>
