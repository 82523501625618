<template>
  <div class="bottom-sheet pt-4 px-2 pb-0" ref="clientContainer">
    <b-card no-body class="h-100 bg-dark-navy custom border-1 pb-0">
        <b-card-header class="px-3 title_staging pb-0">
          <b-row  align-h="between">
            <strong class="px-3 title_staging" style="color: aliceblue;">Full Screen</strong>
            <b-button class="mr-3 btn-yellow rounded-pill mb-1" @click="close()">
              <font-awesome-icon class="fa color-navy"  icon="times"  />
            </b-button>
          </b-row>
        </b-card-header>
        <div class="bottom-sheet-content pt-1">
          <DataAnnotations
          v-if="!bottemSheetState.loading && bottemSheetState.isOpen && clientWidth"
                :fullscreenprop="true" :clientWidth="clientWidth"/>
        <div class="text-center pt-1" v-else>
            <b-spinner scale="4" class="color-yellow"></b-spinner>
            <div>Loading Image...</div>
          </div>
        </div>
    </b-card>
  </div>
</template>

<script>
import { apiRoute } from '../../helpers/api-route'
import DataAnnotations from './components/Annotations/DataAnnotations.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'BottomSheetFullscreen',
  components: {
    DataAnnotations
  },
  props: {
  },
  data () {
    return {
      rejectPayload: [],
      clientWidth: null
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      projects: (state) => state.projectSubmission.projects,
      projectImages: (state) => state.projectSubmission.projectImages,
      projectImagesWatch: (state) => state.projectSubmission.loadingProjectImages,
      userProjects: (state) => state.projectSubmission.projects,
      users: (state) => state.usersSubmission,
      bottemSheetState: (state) => state.BottomSheet
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state.labelledImages,
      imagesWatch: (state) => state.loading,
      imagesAi: (state) => state.aiImages
    }),
    labeledImgWatch () {
      return this.imagesWatch
    },
    aiImgWatch () {
      return this.imagesAi
    },
    proImgWatch () {
      return this.projectImagesWatch
    }
  },
  watch: {
    labeledImgWatch (_new) {
      if (!_new && this.rejectPayload.phase !== 1) {
        console.log('label load')
        var _image = {}
        console.log('images', this.images)
        _image = this.images[0]
        console.log(_image)
        var __image = {
          details: {
            created: _image.image.image.created_at,
            format: _image.image.image.contentType,
            imgName: _image.image.image_set && _image.image.image_set.name ? _image.image.image_set.name : _image.image.image.name,
            iou: _image.image.accuracy.find(ac => ac.project === this.rejectPayload.projectId) ? _image.image.accuracy.find(ac => ac.project === this.rejectPayload.projectId).value : 0,
            aiIov: _image.image.ai_accuracy.filter(a => a.project === this.rejectPayload.projectId).length > 0 ? _image.image.ai_accuracy.filter(a => a.project === this.rejectPayload.projectId).slice(-1)[0].value : null,
            image_set: _image.image.image_set,
            otherImages: _image.otherImages,
            meta: _image.image.metadata,
            pathologies: null
          },
          id: _image.image._id,
          style: 'red',
          url: apiRoute() + '/image/' + _image.image._id,
          analyzed: !!((this.rejectPayload.phase === 7 || this.rejectPayload.phase === 6))
        }
        console.log('image bottom sheet', __image)

        var _payload = {}
        if (this.rejectPayload.phase === 7 || this.rejectPayload.phase === 6) {
          _payload = { labeledSubmissions: this.images, phaseData: this.rejectPayload, members: this.users.members, image: __image, aiImages: this.imagesAi }
        } else {
          _payload = { labeledSubmissions: this.images, phaseData: this.rejectPayload, members: this.users.members, image: __image }
        }
        this.getAnnotationsMap(_payload)
        this.toggleBS(true)
      }
    },
    aiImgWatch (_new) {
      if (_new && this.rejectPayload.phase !== 1) {
        console.log('ai label load')
      }
    },
    proImgWatch (_new) {
      console.log('project iamges')
      console.log(_new)
      console.log(this.projectImages.length)
      if (!_new && this.rejectPayload.phase === 1) {
        console.log('project img load')
        var _image = {}
        this.projectImages.forEach(img => {
          if (img._id === this.rejectPayload.imageID) {
            _image = img
          }
          if (img.otherImages && img.otherImages.length > 0) {
            img.otherImages.forEach(_img => {
              if (_img._id === this.rejectPayload.imageID) {
                _image = img
              }
            })
          }
        })
        console.log(_image)
        var __image = {
          details: {
            created: _image.created_at,
            format: _image.image.contentType,
            imgName: _image.image_set && _image.image_set.name ? _image.image_set.name : _image.image.name,
            iou: 0,
            image_set: _image.image_set,
            otherImages: _image.otherImages,
            meta: _image.metadata,
            pathologies: null
          },
          id: _image._id,
          style: 'red',
          url: apiRoute() + '/image/' + _image._id
        }
        console.log(__image)
        var _payload = { labeledSubmissions: this.images, phaseData: this.rejectPayload, members: this.users.members, image: __image }
        this.getAnnotationsMap(_payload)
        this.toggleBS(true)
      }
    }

  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2Single', 'fetchAILabelledImagesSingle']),
    ...mapActions('projectSubmission', ['getProjectImages2Single', 'getRejections2', 'getAllImages2']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('BottomSheet', {
      toggleBS: 'toggleVisablity',
      getAnnotationsMap: 'getAnnotationsMap',
      bsclear: 'clear'
    }),
    close () {
      window.close()
    }
  },
  mounted () {
    this.toggleBS(false)
    const searchParams = new URLSearchParams(window.location.search)
    var payload = JSON.parse(atob(searchParams.get('payload')))
    this.rejectPayload = payload.phaseData
    console.log(this.rejectPayload)
    this.fetchAILabelledImagesSingle(this.rejectPayload)
    this.getMembers(this.rejectPayload.projectId)
    this.fetchLabelledImages2Single(this.rejectPayload)
    this.getProjectImages2Single(this.rejectPayload)
    console.log('pul')
    // this.annotationLoading = false
    // this.getBatches()
    // this.fetchLabelledImages2(this.project._id)
    // this.fetchAILabelledImages(this.project._id)
    this.clientWidth = this.$refs.clientContainer.clientWidth
  }
}
</script>

<style scoped>

.scrollable {
  overflow-y: auto;
}

.bottom-sheet-content{
  padding-bottom: 0 !important;
}

.bottom-sheet {
  overflow: auto;
  max-height: 100vh;
}
.title_staging  > strong {
    color: aliceblue;
  }
</style>
