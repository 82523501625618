<template>
  <div class="p-4">
    <div
      v-if="membersNotInProject.length === 0 && !loading"
      class="text-center my-4"
    >
      <div>
        <b-icon icon="shield-check" variant="success" font-scale="4"></b-icon>
      </div>
      <div class="mt-2">
        <h5 class="color-yellow">All eligible members have been successfully added to the project.</h5>
      </div>
    </div>

    <div v-else>
      <b-form-input
        v-model="searchQuery"
        placeholder="Search users..."
        class="mb-3"
      ></b-form-input>

      <div class="d-flex justify-content-end" v-if="selectedUsers.length > 0">
        Selected: {{ selectedUsers.length }} / {{ users.length }}
      </div>

      <label>
        <span
          class="selected-users-label"
          v-for="(user, index) in selectedUsers"
          :key="index"
        >
          {{ user.item.personal.fullname }}
        </span>
      </label>

      <div class="table-container">
        <b-table
          :items="filteredUsers"
          :fields="tableFields"
          :row-class="(item) => (item.selected ? 'selected-row' : '')"
        >
          <template #cell(selected)="item">
            <b-form-checkbox
              :value="item.selected"
              @input="toggleUserSelection(item)"
            ></b-form-checkbox>
          </template>
          <template #cell(role)="item">
            <b-form-select
              v-model="item.role"
              :options="roleOptions"
              :value="item.role || 'View Only'"
              @input="updateUserRole(item)"
            ></b-form-select>
          </template>
          <template #head(selected)>
            <span>Add Member</span>
          </template>
        </b-table>
      </div>

      <div v-if="loading" class="text-center my-4">
        <b-spinner></b-spinner>
        <div>Loading users...</div>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <b-button
          class="bg-navy rounded-pill"
          @click="addMembers"
          :disabled="this.selectedUsers.length === 0"
        >
          Add Members(s)
        </b-button>
        <b-button
          variant="secondary"
          @click="closeModal"
          class="mx-1 rounded-pill"
          >Cancel</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      selectedRole: 'View Only',
      roleOptions: [
        { value: 'View Only', text: 'View Only' },
        { value: 'Administrator', text: 'Administrator' },
        { value: 'Reader', text: 'Reader' },
        { value: 'Moderator', text: 'Moderator' },
        { value: 'Data Loader', text: 'Data Loader' },
        { value: 'Data Scientist', text: 'Data Scientist' },
        { value: 'Reporter', text: 'Reporter' }
      ],

      searchQuery: '',
      selectedUsers: []
    }
  },
  computed: {
    ...mapState('usersSubmission', ['loading', 'users', 'membersNotInProject']),
    ...mapState({
      selectedProjectId: (state) => state.projectSubmission.selectedProjectId
    }),
    filteredUsers () {
      const query = this.searchQuery.toLowerCase()
      return this.membersNotInProject
        .map((user) => {
          const existingUser = this.selectedUsers.find(
            (selectedUser) => selectedUser.item._id === user._id
          )

          const fullname = user.personal.fullname.toLowerCase()
          const email = user.personal.email.toLowerCase()
          const qualification = user.personal.qualification.toLowerCase()
          const phone = user.personal.phone.toLowerCase()
          const companyName = user.company.name.toLowerCase()
          const matchesQuery =
            fullname.includes(query) ||
            email.includes(query) ||
            qualification.includes(query) ||
            phone.includes(query) ||
            companyName.includes(query)

          return {
            ...user,
            selected: existingUser ? existingUser.selected : false,
            matchesQuery
          }
        })
        .filter((user) => user.matchesQuery || user.selected)
    },
    tableFields () {
      return [
        {
          key: 'selected',
          label: '',
          class: 'text-center',
          thClass: 'text-center'
        },
        { key: 'personal.fullname', label: 'Full Name' },
        { key: 'personal.qualification', label: 'Qualification' },
        { key: 'personal.email', label: 'Email' },
        { key: 'personal.phone', label: 'Phone' },
        { key: 'company.name', label: 'Company' },
        {
          key: 'role',
          label: 'Role',
          default: 'View Only'
        }
      ]
    }
  },
  methods: {
    getFieldDefaultValue (item, key) {
      const field = this.tableFields.find((field) => field.key === key)
      return field ? field.default : null
    },
    ...mapActions('usersSubmission', [
      'getAll',
      'addMember',
      'getMembers',
      'getMembersNotInProject'
    ]),
    closeModal () {
      this.$emit('close-add-member')
    },
    toggleUserSelection (user) {
      const existingUserIndex = this.selectedUsers.findIndex(
        (selectedUser) => selectedUser.item._id === user.item._id
      )

      if (existingUserIndex === -1) {
        this.selectedUsers.push({ ...user, role: 'View Only', selected: true })
      } else {
        this.selectedUsers.splice(existingUserIndex, 1)
      }
    },

    updateUserRole (user) {
      const selectedUser = this.selectedUsers.find(
        (selectedUser) => selectedUser.item._id === user.item._id
      )
      if (selectedUser) {
        if (
          selectedUser.role !== user.role ||
          typeof selectedUser.role === 'undefined'
        ) {
          selectedUser.role = user.role
        }
      }
    },

    async addMembers () {
      const membersWithRoles = this.selectedUsers.map((selectedUser) => ({
        members: selectedUser.item._id,
        role: selectedUser.role
      }))
      const projectId = this.selectedProjectId
      await this.addMember({ projectId, membersWithRoles })
      await this.getMembers(projectId)
      this.$emit('close-add-member')
    }
  },
  mounted () {
    this.getMembersNotInProject(this.selectedProjectId)
  }
}
</script>
<style scoped>
.selected-users-label {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 5px;
  background-color: #f0b93b;
  color: rgb(54, 54, 54);
  border-color: #f0b93b;
  font-weight: 400;
}

.selected-users-chip {
  margin-right: 5px;
}
</style>
