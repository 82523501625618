<template>
  <div>
    <b-form-group
      class="mb-0"
      v-for="(roleObj, index) in updatedMember.projectRole"
      :key="index"
    >
      <div v-if="selectedProjectId === roleObj.project" class="mb-2">
        <label :for="'role-' + index">Project Role</label>
        <b-form-select
          :id="'role-' + index"
          v-model="roleObj.role"
          :options="roleOptions"
          v-if="selectedProjectId === roleObj.project"
        ></b-form-select>
      </div>
    </b-form-group>

    <!-- Member information inputs -->
    <b-form-group label="Full Name">
      <b-form-input
        v-model="updatedMember.personal.fullname"
        v-if="updatedMember"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Qualification">
      <b-form-input
        v-model="updatedMember.personal.qualification"
        v-if="updatedMember"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Email" :invalid-feedback="emailInvalidFeedback">
      <b-form-input
        v-model="updatedMember.personal.email"
        type="email"
        :state="emailValidationState"
        :disabled="true"
        @input="validateEmail"
        v-if="updatedMember"
      ></b-form-input>
      <small class="color-yellow"
        >Oops! Email editing is not possible right now. Please reach out to the
        admin for help.</small
      >
    </b-form-group>
    <b-form-group label="Phone" :invalid-feedback="phoneInvalidFeedback">
      <b-form-input
        v-model="updatedMember.personal.phone"
        :state="phoneValidationState"
        @input="validatePhone"
        v-if="updatedMember"
      ></b-form-input>
    </b-form-group>

    <!-- Save and cancel buttons -->
    <div class="button-group d-flex justify-content-end">
      <b-button variant="secondary" @click="closeModal" class="mr-2 rounded-pill"
        >Cancel</b-button
      >
      <b-button
        class="rounded-pill bg-navy"
        :disabled="hasInvalidInput"
        @click="updateMember"
        v-if="updatedMember"
        >Save</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      updatedMember: {},
      fullnameInvalidFeedback: '',
      emailInvalidFeedback: '',
      phoneInvalidFeedback: '',
      roleOptions: [
        'Administrator',
        'Reader',
        'Moderator',
        'Data Loader',
        'Data Scientist',
        'Reporter',
        'View Only'
      ]
    }
  },
  methods: {
    ...mapActions('usersSubmission', {
      updateUser: 'updateUser'
    }),
    updateMember () {
      this.updateUser(this.updatedMember)
      this.fullnameInvalidFeedback = ''
      this.emailInvalidFeedback = ''
      this.phoneInvalidFeedback = ''
      this.$emit('close-modal')
    },
    closeModal () {
      this.showModal = false
      this.fullnameInvalidFeedback = ''
      this.emailInvalidFeedback = ''
      this.phoneInvalidFeedback = ''
      this.$emit('close-modal')
    },
    resetModal () {
      this.showModal = false
      this.updatedMember = {}
      this.fullnameInvalidFeedback = ''
      this.emailInvalidFeedback = ''
      this.phoneInvalidFeedback = ''
    },
    validateFullname () {
      if (!this.updatedMember.personal.fullname) {
        this.fullnameInvalidFeedback = 'Full name is required'
      } else {
        this.fullnameInvalidFeedback = ''
      }
    },
    validateEmail () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(this.updatedMember.personal.email)) {
        this.emailInvalidFeedback = 'Invalid email format'
      } else {
        this.emailInvalidFeedback = ''
      }
    },
    validatePhone () {
      const phoneRegex = /^\d+$/
      if (!phoneRegex.test(this.updatedMember.personal.phone)) {
        this.phoneInvalidFeedback = 'Phone number must contain only digits'
      } else {
        this.phoneInvalidFeedback = ''
      }
    }
  },
  watch: {
    member: {
      immediate: true,
      handler (value) {
        this.updatedMember = { ...value }

        // Initialize roleObj for the selected project if not exists
        const existingRole = this.updatedMember.projectRole.find(
          (roleObj) => roleObj.project === this.selectedProjectId
        )
        if (!existingRole) {
          this.updatedMember.projectRole.push({
            project: this.selectedProjectId,
            role: 'Reader' // Set a default role if needed
          })
        }
      }
    },
    'updatedMember.personal.fullname': {
      handler () {
        this.validateFullname()
      }
    },
    'updatedMember.personal.email': {
      handler () {
        this.validateEmail()
      }
    },
    'updatedMember.personal.phone': {
      handler () {
        this.validatePhone()
      }
    }
  },
  computed: {
    ...mapState({
      selectedProjectId: (state) => state.projectSubmission.selectedProjectId,
      projects: (state) => state.projectSubmission.projects
    }),
    fullnameValidationState () {
      return this.fullnameInvalidFeedback ? false : null
    },
    emailValidationState () {
      return this.emailInvalidFeedback ? false : null
    },
    phoneValidationState () {
      return this.phoneInvalidFeedback ? false : null
    },
    hasInvalidInput () {
      return (
        !!this.fullnameInvalidFeedback ||
        !!this.emailInvalidFeedback ||
        !!this.phoneInvalidFeedback
      )
    }
  },
  mounted () {
    // // console.log('these are the projects that', this.projects)
    // const projectRole = this.projects.find(
    //   (project) => project._id === this.selectedProjectId
    // )._id
    // // console.log('projectRole', projectRole)
  }
}
</script>
